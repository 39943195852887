import { default as _91_46_46_46slug_93SpSy9qvVWdMeta } from "/github/workspace/pages/[...slug].vue?macro=true";
import { default as contact_45usYIfIAvAMoKMeta } from "/github/workspace/pages/contact-us.vue?macro=true";
import { default as financing8IepeDtFIkMeta } from "/github/workspace/pages/financing.vue?macro=true";
import { default as confirmed_45inspectionOikJnNOAI9Meta } from "/github/workspace/pages/ghl/confirmed-inspection.vue?macro=true";
import { default as ghl_45contact_45opportunity_45syncMUFIl83csjMeta } from "/github/workspace/pages/ghl/ghl-contact-opportunity-sync.vue?macro=true";
import { default as ghl_45opportunity_45creationsi2RPlprW2Meta } from "/github/workspace/pages/ghl/ghl-opportunity-creation.vue?macro=true";
import { default as inspection_45existsYlkmrCQNTxMeta } from "/github/workspace/pages/ghl/inspection-exists.vue?macro=true";
import { default as schedule_45inspectioncY0z9QEIJMMeta } from "/github/workspace/pages/ghl/schedule-inspection.vue?macro=true";
import { default as indexhKnGp5KL0kMeta } from "/github/workspace/pages/index.vue?macro=true";
import { default as indexvcyUy4PVixMeta } from "/github/workspace/pages/map/index.vue?macro=true";
import { default as our_45teamOXyrl0VoHsMeta } from "/github/workspace/pages/our-team.vue?macro=true";
import { default as request_45quotejMfpbg5AyrMeta } from "/github/workspace/pages/request-quote.vue?macro=true";
import { default as asphaltKdK3NXSq9jMeta } from "/github/workspace/pages/roof-types/asphalt.vue?macro=true";
import { default as cedarQhuDiZZtU8Meta } from "/github/workspace/pages/roof-types/cedar.vue?macro=true";
import { default as metalTHtFCvqs7oMeta } from "/github/workspace/pages/roof-types/metal.vue?macro=true";
import { default as emergencypJe66GEDDTMeta } from "/github/workspace/pages/roofing-services/emergency.vue?macro=true";
import { default as roof_45replacementszQeXWaof4YMeta } from "/github/workspace/pages/roofing-services/roof-replacements.vue?macro=true";
import { default as confirmed_45inspectionHynxcqucx1Meta } from "/github/workspace/pages/sr/confirmed-inspection.vue?macro=true";
import { default as inspection_45existsRW4dXhuIpRMeta } from "/github/workspace/pages/sr/inspection-exists.vue?macro=true";
import { default as schedule_45inspectionRMumTOxBbHMeta } from "/github/workspace/pages/sr/schedule-inspection.vue?macro=true";
import { default as thank_45youn8FgIXFcFPMeta } from "/github/workspace/pages/thank-you.vue?macro=true";
export default [
  {
    name: _91_46_46_46slug_93SpSy9qvVWdMeta?.name ?? "slug",
    path: _91_46_46_46slug_93SpSy9qvVWdMeta?.path ?? "/:slug(.*)*",
    meta: _91_46_46_46slug_93SpSy9qvVWdMeta || {},
    alias: _91_46_46_46slug_93SpSy9qvVWdMeta?.alias || [],
    redirect: _91_46_46_46slug_93SpSy9qvVWdMeta?.redirect,
    component: () => import("/github/workspace/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: contact_45usYIfIAvAMoKMeta?.name ?? "contact-us",
    path: contact_45usYIfIAvAMoKMeta?.path ?? "/contact-us",
    meta: contact_45usYIfIAvAMoKMeta || {},
    alias: contact_45usYIfIAvAMoKMeta?.alias || [],
    redirect: contact_45usYIfIAvAMoKMeta?.redirect,
    component: () => import("/github/workspace/pages/contact-us.vue").then(m => m.default || m)
  },
  {
    name: financing8IepeDtFIkMeta?.name ?? "financing",
    path: financing8IepeDtFIkMeta?.path ?? "/financing",
    meta: financing8IepeDtFIkMeta || {},
    alias: financing8IepeDtFIkMeta?.alias || [],
    redirect: financing8IepeDtFIkMeta?.redirect,
    component: () => import("/github/workspace/pages/financing.vue").then(m => m.default || m)
  },
  {
    name: confirmed_45inspectionOikJnNOAI9Meta?.name ?? "ghl-confirmed-inspection",
    path: confirmed_45inspectionOikJnNOAI9Meta?.path ?? "/ghl/confirmed-inspection",
    meta: confirmed_45inspectionOikJnNOAI9Meta || {},
    alias: confirmed_45inspectionOikJnNOAI9Meta?.alias || [],
    redirect: confirmed_45inspectionOikJnNOAI9Meta?.redirect,
    component: () => import("/github/workspace/pages/ghl/confirmed-inspection.vue").then(m => m.default || m)
  },
  {
    name: ghl_45contact_45opportunity_45syncMUFIl83csjMeta?.name ?? "ghl-ghl-contact-opportunity-sync",
    path: ghl_45contact_45opportunity_45syncMUFIl83csjMeta?.path ?? "/ghl/ghl-contact-opportunity-sync",
    meta: ghl_45contact_45opportunity_45syncMUFIl83csjMeta || {},
    alias: ghl_45contact_45opportunity_45syncMUFIl83csjMeta?.alias || [],
    redirect: ghl_45contact_45opportunity_45syncMUFIl83csjMeta?.redirect,
    component: () => import("/github/workspace/pages/ghl/ghl-contact-opportunity-sync.vue").then(m => m.default || m)
  },
  {
    name: ghl_45opportunity_45creationsi2RPlprW2Meta?.name ?? "ghl-ghl-opportunity-creation",
    path: ghl_45opportunity_45creationsi2RPlprW2Meta?.path ?? "/ghl/ghl-opportunity-creation",
    meta: ghl_45opportunity_45creationsi2RPlprW2Meta || {},
    alias: ghl_45opportunity_45creationsi2RPlprW2Meta?.alias || [],
    redirect: ghl_45opportunity_45creationsi2RPlprW2Meta?.redirect,
    component: () => import("/github/workspace/pages/ghl/ghl-opportunity-creation.vue").then(m => m.default || m)
  },
  {
    name: inspection_45existsYlkmrCQNTxMeta?.name ?? "ghl-inspection-exists",
    path: inspection_45existsYlkmrCQNTxMeta?.path ?? "/ghl/inspection-exists",
    meta: inspection_45existsYlkmrCQNTxMeta || {},
    alias: inspection_45existsYlkmrCQNTxMeta?.alias || [],
    redirect: inspection_45existsYlkmrCQNTxMeta?.redirect,
    component: () => import("/github/workspace/pages/ghl/inspection-exists.vue").then(m => m.default || m)
  },
  {
    name: schedule_45inspectioncY0z9QEIJMMeta?.name ?? "ghl-schedule-inspection",
    path: schedule_45inspectioncY0z9QEIJMMeta?.path ?? "/ghl/schedule-inspection",
    meta: schedule_45inspectioncY0z9QEIJMMeta || {},
    alias: schedule_45inspectioncY0z9QEIJMMeta?.alias || [],
    redirect: schedule_45inspectioncY0z9QEIJMMeta?.redirect,
    component: () => import("/github/workspace/pages/ghl/schedule-inspection.vue").then(m => m.default || m)
  },
  {
    name: indexhKnGp5KL0kMeta?.name ?? "index",
    path: indexhKnGp5KL0kMeta?.path ?? "/",
    meta: indexhKnGp5KL0kMeta || {},
    alias: indexhKnGp5KL0kMeta?.alias || [],
    redirect: indexhKnGp5KL0kMeta?.redirect,
    component: () => import("/github/workspace/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexvcyUy4PVixMeta?.name ?? "map",
    path: indexvcyUy4PVixMeta?.path ?? "/map",
    meta: indexvcyUy4PVixMeta || {},
    alias: indexvcyUy4PVixMeta?.alias || [],
    redirect: indexvcyUy4PVixMeta?.redirect,
    component: () => import("/github/workspace/pages/map/index.vue").then(m => m.default || m)
  },
  {
    name: our_45teamOXyrl0VoHsMeta?.name ?? "our-team",
    path: our_45teamOXyrl0VoHsMeta?.path ?? "/our-team",
    meta: our_45teamOXyrl0VoHsMeta || {},
    alias: our_45teamOXyrl0VoHsMeta?.alias || [],
    redirect: our_45teamOXyrl0VoHsMeta?.redirect,
    component: () => import("/github/workspace/pages/our-team.vue").then(m => m.default || m)
  },
  {
    name: request_45quotejMfpbg5AyrMeta?.name ?? "request-quote",
    path: request_45quotejMfpbg5AyrMeta?.path ?? "/request-quote",
    meta: request_45quotejMfpbg5AyrMeta || {},
    alias: request_45quotejMfpbg5AyrMeta?.alias || [],
    redirect: request_45quotejMfpbg5AyrMeta?.redirect,
    component: () => import("/github/workspace/pages/request-quote.vue").then(m => m.default || m)
  },
  {
    name: asphaltKdK3NXSq9jMeta?.name ?? "roof-types-asphalt",
    path: asphaltKdK3NXSq9jMeta?.path ?? "/roof-types/asphalt",
    meta: asphaltKdK3NXSq9jMeta || {},
    alias: asphaltKdK3NXSq9jMeta?.alias || [],
    redirect: asphaltKdK3NXSq9jMeta?.redirect,
    component: () => import("/github/workspace/pages/roof-types/asphalt.vue").then(m => m.default || m)
  },
  {
    name: cedarQhuDiZZtU8Meta?.name ?? "roof-types-cedar",
    path: cedarQhuDiZZtU8Meta?.path ?? "/roof-types/cedar",
    meta: cedarQhuDiZZtU8Meta || {},
    alias: cedarQhuDiZZtU8Meta?.alias || [],
    redirect: cedarQhuDiZZtU8Meta?.redirect,
    component: () => import("/github/workspace/pages/roof-types/cedar.vue").then(m => m.default || m)
  },
  {
    name: metalTHtFCvqs7oMeta?.name ?? "roof-types-metal",
    path: metalTHtFCvqs7oMeta?.path ?? "/roof-types/metal",
    meta: metalTHtFCvqs7oMeta || {},
    alias: metalTHtFCvqs7oMeta?.alias || [],
    redirect: metalTHtFCvqs7oMeta?.redirect,
    component: () => import("/github/workspace/pages/roof-types/metal.vue").then(m => m.default || m)
  },
  {
    name: emergencypJe66GEDDTMeta?.name ?? "roofing-services-emergency",
    path: emergencypJe66GEDDTMeta?.path ?? "/roofing-services/emergency",
    meta: emergencypJe66GEDDTMeta || {},
    alias: emergencypJe66GEDDTMeta?.alias || [],
    redirect: emergencypJe66GEDDTMeta?.redirect,
    component: () => import("/github/workspace/pages/roofing-services/emergency.vue").then(m => m.default || m)
  },
  {
    name: roof_45replacementszQeXWaof4YMeta?.name ?? "roofing-services-roof-replacements",
    path: roof_45replacementszQeXWaof4YMeta?.path ?? "/roofing-services/roof-replacements",
    meta: roof_45replacementszQeXWaof4YMeta || {},
    alias: roof_45replacementszQeXWaof4YMeta?.alias || [],
    redirect: roof_45replacementszQeXWaof4YMeta?.redirect,
    component: () => import("/github/workspace/pages/roofing-services/roof-replacements.vue").then(m => m.default || m)
  },
  {
    name: confirmed_45inspectionHynxcqucx1Meta?.name ?? "sr-confirmed-inspection",
    path: confirmed_45inspectionHynxcqucx1Meta?.path ?? "/sr/confirmed-inspection",
    meta: confirmed_45inspectionHynxcqucx1Meta || {},
    alias: confirmed_45inspectionHynxcqucx1Meta?.alias || [],
    redirect: confirmed_45inspectionHynxcqucx1Meta?.redirect,
    component: () => import("/github/workspace/pages/sr/confirmed-inspection.vue").then(m => m.default || m)
  },
  {
    name: inspection_45existsRW4dXhuIpRMeta?.name ?? "sr-inspection-exists",
    path: inspection_45existsRW4dXhuIpRMeta?.path ?? "/sr/inspection-exists",
    meta: inspection_45existsRW4dXhuIpRMeta || {},
    alias: inspection_45existsRW4dXhuIpRMeta?.alias || [],
    redirect: inspection_45existsRW4dXhuIpRMeta?.redirect,
    component: () => import("/github/workspace/pages/sr/inspection-exists.vue").then(m => m.default || m)
  },
  {
    name: schedule_45inspectionRMumTOxBbHMeta?.name ?? "sr-schedule-inspection",
    path: schedule_45inspectionRMumTOxBbHMeta?.path ?? "/sr/schedule-inspection",
    meta: schedule_45inspectionRMumTOxBbHMeta || {},
    alias: schedule_45inspectionRMumTOxBbHMeta?.alias || [],
    redirect: schedule_45inspectionRMumTOxBbHMeta?.redirect,
    component: () => import("/github/workspace/pages/sr/schedule-inspection.vue").then(m => m.default || m)
  },
  {
    name: thank_45youn8FgIXFcFPMeta?.name ?? "thank-you",
    path: thank_45youn8FgIXFcFPMeta?.path ?? "/thank-you",
    meta: thank_45youn8FgIXFcFPMeta || {},
    alias: thank_45youn8FgIXFcFPMeta?.alias || [],
    redirect: thank_45youn8FgIXFcFPMeta?.redirect,
    component: () => import("/github/workspace/pages/thank-you.vue").then(m => m.default || m)
  }
]